<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="$t('notifications.title')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"

        :btn-title="$t('notifications.new_btn')"

        :route-name="'system-notifications-create'"
    />

    <div>
      <filter-header
          :content='filterContent'
          @applyFilter='applyFilter'
          @resetFilter='resetFilter'
          @orderApplicants='orderData'

          :has-order='true'
      />
    </div>
    <section
        class='
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      '>
      <div class='d-flex flex-wrap flex-1'>
        <b-col lg='12' md='12' class='px-0'>

          <div class='w-table d-flex flex-column'>
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if='getData'
                :per-page='filterData.per_page'
                :items='getData'
                :fields='tableFields'
                :custimized-items="[
                { name: 'name' },
                { name: 'created_at' },
                { name: 'target' },
                { name: 'action' },
              ]"
            >

              <template slot='name' slot-scope='{ data }'>
                <name-tile
                    :name='data.item.title'
                    :image='data.item.image'
                    :data-id='data.item.id'
                />
              </template>


              <template slot='created_at' slot-scope='{ data }'>
                {{ formatDate(data.item.created_at, 'YYYY-MM-DD') }}
              </template>
              <template slot='target' slot-scope='{ data }'>
                {{ formatNotificationTargets(data.item.target) }}
              </template>

              <template slot='action' slot-scope='{ data }'
                        v-if='data.item.type==null'>


                <b-dropdown
                    variant='background'
                    class='table-option'
                    toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                    menu-class='rounded-8'
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      v-if="data.item.is_scheduled==1"
                      @click="()=> updatePage(data.item)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item

                      v-if="data.item.is_scheduled==1"
                      @click="deleteModal = true; deleteModalData = data.item">
                    <span class="text-regular-14  text-danger">
                      <trash-icon class="mx-2 "/>
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      class='permission-edit-item'
                      v-permission='[$route.meta.pagePermissions.read]'
                      :to='goToDetail(data.item.id)'
                  >
                    <span class='text-regular-14 text-font-secondary'>

                      <eyeon-icon class='mx-2'/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg='12' md='12' class='px-0'>
          <wameed-pagination
              :items='paginationDropDownItems'
              v-if='getTotal'
              v-model='filterData.page'
              :page='filterData.page'
              :total-items='getTotal.totalItems'
              :per_page='filterData.per_page'
              :menu-title="$t('common.show_results')"
              @changePage='changePage'
              @changeCurrentPage='changeCurrentPage'
          />
        </b-col>

      </div>

    </section>


    <warning-modal
        variant='danger'
        iconName='trash-icon'
        :visible='deleteModal'
        @close='deleteModal = false'
        @submitAction='deleteAction'
        :title="$t('notifications.modal.delete')"
        :subTitle="$t('notifications.modal.delete_desc')"
        :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from '@/components/WarningModal';

import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm';
import NameTile from '@/components/nameTile.table';
import {filterUserTarget} from '@/enums/targetTypes.enum';
import {notificationTarget} from '@/enums/notificationTarget.enum';

export default {
  components: {
    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      deleteModalData: null,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'order',
        search: ''
      },
      filterContent: [
        {
          title: this.$i18n.t('table.availability'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.available')
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_available')
            }
          ]
        },
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false

        },
        {
          key: 'name',
          label: this.$i18n.t('notifications.table.title'),
          sortable: false,
          tdClass: 'w-1/5'
        },

        {
          key: 'content',
          label: this.$i18n.t('notifications.table.content'),
          sortable: false,
          tdClass: 'w-2/5'
        },

        {
          key: 'target',
          label: this.$i18n.t('notifications.table.target'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'created_at',
          label: this.$i18n.t('notifications.table.date'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/notifications/getData',
      getTotal: 'admin/settings/notifications/getTotal',
    }),


    paginationDropDownItems() {
      return [
        {
          title: `20 - 1 `,
          id: 20
        },
        {
          title: `40 - 1 `,
          id: 40
        },
        {
          title: `60 - 1 `,
          id: 60
        },
        {
          title: this.$i18n.t('common.all'),
          id: this.getTotal.totalItems
        }
      ];
    }
  },
  async created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/notifications/get',
      _deleteData: 'admin/settings/notifications/delete'
    }),


    goToDetail(id) {

      return {
        name: 'system-notifications-detail',
        params: {lang: this.$i18n.locale, id}
      };
    },
    formatNotificationTargets(targets) {

      let formattedTargets = '';
      targets.forEach((target, index) => {
        if (target.key === notificationTarget.usersType) {
          formattedTargets += filterUserTarget(target.value).name;
        } else if (target.key === notificationTarget.gender) {
          let genders = target.value.toString().split(',');
          if (genders.length > 1) {
            // formattedTargets += this.$t('common.males') + ',' + this.$t('common.females');
          } else {
            if (genders[0] == 1) {
              formattedTargets += this.$t('common.males');
            } else {
              formattedTargets += this.$t('common.females');
            }
          }
        } else if (target.key === notificationTarget.age) {
          let value = target.value.toString().split(',');
          formattedTargets += this.$t('common.from') + ' ' + value[0] + ' ' + this.$t('common.to') + ' ' + value[1] + ' ' + this.$t('common._year');

        }

        if (index !== targets.length - 1 && formattedTargets !== '') {
          formattedTargets += ', ';
        }
      });
      return formattedTargets;
    },

    updatePage(item) {

      this.$router.push({
        name: 'system-notifications-update',
        params: {lang: this.$i18n.locale, id: item.id}
      });

    },

    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }

      this.$store.dispatch('admin/settings/notifications/toggle', {id: item.id, status: status});
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },

    deleteAction() {
      this.deleteModal = false;
      this._deleteData({id: this.deleteModalData.id}).then(() => {
        this.loadData();
      })
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },

    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item
      };

      this.loadData();
    },


    order(id, oldIndex, newIndex) {
      this.$store.dispatch('admin/settings/notifications/reorder', {
        id: id,
        old_order: oldIndex,
        new_order: newIndex,
        page: this.filterData.page,
        per_page: this.filterData.per_page
      });
    }

  }

};
</script>
